.App {
  display: flex;
  align-items: center;
  color: white;
  text-align: center;
  min-height: 100vh;
  flex-direction: column;
  font-size: calc(9px + 2vmin);
  max-width: 544px;
  padding-bottom: 20px;
}

.cover {
  background: url(/public/cover.jpeg) no-repeat;
  width: 120%;
  height: 25%;
  min-height: 200px;
  max-height: 300px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  border-radius: 0 0 8px 8px;
}

img {
  margin-top: -70px;
  margin-bottom: 20px;
  border: 7px solid #282c34;
}

.App-logo {
  border-radius: 50%;
}

.App-social {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  justify-content: center;
}
.App-social-link {
  width: 30%;
  color: #fff;
  font-size: calc(17px + 4.3vmin);
}

.title-content {
  margin: 10px 0 20px;
}
.title {
  margin: 10px 0;
  font-size: calc(8px + 2vmin);
}

.sub-title {
  margin: 8px 0;
  font-size: calc(5px + 1.7vmin);
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-email {
  color: white;
  margin: 10px;
}

.projects {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.App-link {
  border: 3px solid #fff;
  border-radius: 50px;
  padding: 18px 0;
  color: white;
  text-decoration: none;
  margin: 15px 0 15px -3px;
  width: 100%;
}
